body {
  margin: 0;
  font-family: 'Source Sans Pro',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background: url(img/windjoch2007_cropped.jpg) no-repeat fixed center;
  /* background: url(img/picos.jpg) no-repeat fixed center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.content {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  /*margin: 10% auto 10% auto;*/
  background: rgba(0, 0, 0, 0.4);
  padding-top: 20px;
  padding-bottom: 20px;
}

h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0;
}

h2 {
  color: #95a5bd;
  font-weight: 200;
  font-size: 25px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 0;
}

p {
  font-size: 15px;
  text-align: left;
  line-height: 1.7em;
  max-width: 500px;
  margin: 0 auto;
  width: 70%;
}

ul.social {
  list-style: none;
  display: block;
  margin: 20px auto 0 auto;
  padding: 0;

  letter-spacing: 3px;
  text-transform: uppercase;
  color: #95a5bd;
  font-size: 17px;
}

ul.social li {
  display: inline-block;
}

ul.social li a {
  color: #fff;
  text-decoration: none;
}

/*ul.social li a:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}*/

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 19px;
  }
  p {
    font-size: 12px;
  }
  ul.social {
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 14px;
  }
  p {
    font-size: 8px;
  }
  ul.social {
    font-size: 9px;
  }
}
